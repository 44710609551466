import React, { createContext, useReducer } from 'react';

const initialState = {
  isLoggedIn: false,
  isInTheatreMore: false,
};

const store = createContext(initialState);
const { Provider } = store;

const STORE_ACTIONS = {
  TOGGLE_THEATRE_MODE: 'TOGGLE_THEATRE_MODE',
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case STORE_ACTIONS.TOGGLE_THEATRE_MODE:
        return {
          ...state,
          isInTheatreMore: !state.isInTheatreMore,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, STORE_ACTIONS, StateProvider };
