import styled from 'styled-components';
import Container from '../components/Container';

export const Wrapper = styled.div`
  margin: 0 0 5rem;

  .logo {
    max-width: 10rem;
    margin: 2rem 0;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      max-width: 15rem;
      margin: 4rem 0;
    }
  }
`;

export const Intro = styled.div`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: 60vw;
  }
  h1 {
    font-weight: normal;

    span {
      color: var(--color-primary);
      font-weight: bold;
    }
  }
`;

export const FullWidthImage = styled.div`
  margin: 5rem 0;

  ${Container} {
    padding: 0;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      padding: 0 2rem;
    }
  }

  img {
    aspect-ratio: 16/9;
    @media (${({ theme }) => theme.respondTo.desktop}) {
      max-width: 60vw;
    }
  }
`;

export const TextBlock = styled.div`
  margin: 5rem 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: 60vw;
  }

  h2 {
    text-transform: uppercase;
    font-size: var(--font-size-base);
    color: var(--color-primary);
  }
`;
