import React, { useEffect, useState } from 'react';
import LazyImage from '../LazyImage/LazyImage';
import { Wrapper } from './ImageSlider.styles';

const ImageSlider = ({ items }) => {
  const [fromLeft, setFromLeft] = useState(null);

  useEffect(() => {
    const H1 = document.querySelector('h1');
    const distanceFromLeft = H1.getBoundingClientRect().left;
    setFromLeft(distanceFromLeft);
  }, []);

  return (
    <Wrapper fromLeft={fromLeft}>
      {items.map(item => (
        <LazyImage key={item.url} className="slider-img" src={item.url} alt="Efata Indonesia" />
      ))}
    </Wrapper>
  );
};

export default ImageSlider;
