import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: var(--max-container-width);

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 0 2rem;
  }
`;

export default Container;
