import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 5rem 0;

  h2 {
    text-transform: uppercase;
    font-size: var(--font-size-base);
    color: var(--color-primary);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding-left: 3.2rem;
      margin: 0 0 1rem;

      @media (${({ theme }) => theme.respondTo.desktop}) {
        padding-left: 3.2rem;
      }

      &:before {
        content: url('/images/check.svg');
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
`;
