import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 5rem 0;
  padding-left: 1rem;
  display: flex;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-left: ${({ fromLeft }) => `${fromLeft}px` || '2rem'};
  }

  > div {
    flex-shrink: 0;
    margin-right: 1rem;

    img {
      height: 20rem;
      width: auto;

      @media (${({ theme }) => theme.respondTo.desktop}) {
        height: 40rem;
      }
    }
  }
`;
