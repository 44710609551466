import React from 'react';
import { Wrapper } from './List.styles';

const List = ({ title, items }) => {
  return (
    <Wrapper>
      {title && <h2>{title}</h2>}
      <ul>
        {items.map(item => (
          <li key={item.text}>{item.text}</li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default List;
