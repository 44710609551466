import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    position: relative;
    width: 100%;
    object-fit: cover;

    &.loading,
    &.lazy {
      transition: opacity 0.5s ease-in;
      opacity: 0;
    }

    &.loaded {
      opacity: 1;
    }
  }
`;
