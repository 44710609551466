import React from 'react';
import Container from '../components/Container';
import { Wrapper } from './layout.styles';

const Layout = ({ children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {children}
      <footer>
        <Container>
          <a href="mailto:info@efata-indonesia.nl">info@efata-indonesia.nl</a> — KVK: 83896813
        </Container>
      </footer>
    </Wrapper>
  );
};

export default Layout;
