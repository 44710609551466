import React from 'react';
import { LazyMotion, domAnimation } from 'framer-motion';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './routes';
import Home from './pages/Home';
import GlobalStyle from './styling/GlobalStyle';

const App = () => {
  return (
    <main>
      <GlobalStyle />
      <LazyMotion features={domAnimation}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </LazyMotion>
    </main>
  );
};

export default App;
