import React from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from '../layouts/layout';
import Container from '../components/Container';
import { Wrapper, Intro, FullWidthImage, TextBlock } from './Home.styles';
import ImageSlider from '../components/ImageSlider/ImageSlider';
import List from '../components/List/List';
import LazyImage from '../components/LazyImage/LazyImage';

const Home = () => {
  const images = [
    {
      url: '/images/efata6.jpeg',
    },
    {
      url: '/images/efata2.jpeg',
    },
    {
      url: '/images/efata3.jpeg',
    },
    {
      url: '/images/efata4.jpeg',
    },
    {
      url: '/images/efata5.jpeg',
    },
    {
      url: '/images/efata1.jpeg',
    },
    {
      url: '/images/efata7.jpeg',
    },
  ];

  const listItems = [
    {
      text: 'Voorlichting en educatie op gebied van gezondheid, gezonde woonomgeving en bomen planten. In het algemeen zorgen voor een gezond milieu.',
    },
    {
      text: 'Financiële hulp en hulp in natura bieden aan mensen die dat nodig hebben.',
    },
    {
      text: 'Jongeren motiveren om aan een gezond milieu mee te werken.',
    },
    {
      text: 'Werkloze jongeren mobiliseren om zelfstandig en maatschappelijk bewust te zijn.',
    },
    {
      text: 'Hulp bij calamiteiten, oa: rampen als aardbevingen en overstromingen.',
    },
    {
      text: 'Hulp bieden aan zwerfdieren. ',
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>Efata Indonesia</title>
        <meta
          name="description"
          content="Stichting Efata Indonesia is opgericht door Rosbina Tarigan. Rosbina is een Indonesische dame opgegroeid in een dorp in de Noord Sumatra, Indonesia."
        />
      </Helmet>

      <Container>
        <Wrapper>
          <LazyImage className="logo" src="/images/logo.jpg" />
          <Intro>
            <h1>
              <span>Stichting Efata Indonesia</span> biedt hulp, voorlichting & motiveert kansarme mensen in Indonesië.
            </h1>
          </Intro>
        </Wrapper>
      </Container>

      <ImageSlider items={images} />

      <Container>
        <List title="De doelstellingen" items={listItems} />
      </Container>

      <FullWidthImage>
        <Container>
          <LazyImage src="/images/efata8.jpeg" />
        </Container>
      </FullWidthImage>

      <Container>
        <TextBlock>
          <h2>Wie we zijn</h2>
          <p>
            Stichting Efata Indonesia is opgericht door Rosbina Tarigan. Rosbina is een Indonesische dame opgegroeid in
            een dorp in de Noord Sumatra, Indonesia.
          </p>
          <p>
            Al jarenlang heeft Rosbina families en ook individuen geholpen in het dorp waar ze woonde. Samen met
            vrienden uit Nederland helpt ze mensen in verschillende provincies in Indonesia en ook in andere landen
            zoals Afrika, Nepal, Midden-Oosten en Nederland.
          </p>
          <p>
            Ze stuurt kledingpakketten, voedsel, schoolmaterialen, studiegeld, medicijnen, giften,
            gezondheidshulpmiddelen, behandeling in het ziekenhuis, ondersteuning bij het bouwen van het huis,
            ondersteuning bij een onderneming opstarten en bij verschillende kerkactiviteiten waaronder hulp aan
            daklozen.
          </p>
        </TextBlock>
      </Container>
    </Layout>
  );
};

export default Home;
