import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  footer {
    border-top: 1px solid lightgray;
    margin: 5rem 0 0;
    text-align: center;
    padding: 2rem 0;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      padding: 2.4rem 0;
    }
  }
`;
